import { maybeInstallMembersArea } from '@wix/members-area-integration-kit';
import {
  PageRef,
  JsonObject,
  EditorSDK,
  AppEditorApi,
  InstallationOriginType,
  EditorPlatformApp,
} from '@wix/platform-editor-sdk';
import PricingPlans from '../../.application.json';
import { addPlanListBlocksWidget } from '../blocks-widgets-editor/plan-list';
import { addSinglePlanWidget } from '../blocks-widgets-editor/single-plan';
import { Context } from '../editor.app';
import { PrivatePricingPlansApi, PublicPricingPlansApi } from '../types/editor';
import { captureEditorException } from '../utils/editor';
import {
  getContainerRef,
  isSinglePlanWidgetRef,
  isContainerRef,
  getPlanWidget,
  getCurrentPresetId,
} from '../utils/widget';

const TOKEN = '';

export const privateApi = (getContext: () => Context): PrivatePricingPlansApi => ({
  async openAddons(focusPackagePicker = false) {
    const { flowAPI, editorSDK } = getContext();
    flowAPI.errorMonitor.addBreadcrumb({ message: 'PP privateApi.openAddons' });
    const { openAddonsModal } = await import('../utils/settings');
    await openAddonsModal(editorSDK, flowAPI, focusPackagePicker);
  },
  async openSettings() {
    const { flowAPI, editorSDK } = getContext();
    flowAPI.errorMonitor.addBreadcrumb({ message: 'PP privateApi.openSettings' });
    const { openPricingPlansSettings } = await import('../utils/settings');
    await openPricingPlansSettings(editorSDK, flowAPI.bi);
  },
  async addPlanListWidget(pageId: string) {
    const { flowAPI, editorSDK } = getContext();
    flowAPI.errorMonitor.addBreadcrumb({ message: 'PP privateApi.addPlanListWidget' });
    const { addPlanListWidgetToStage } = await import('../utils/components');
    await addPlanListWidgetToStage(editorSDK, pageId);
  },
  async addSinglePlanBlocksWidget(pageId: string, planId: string) {
    const { flowAPI, editorSDK } = getContext();
    flowAPI.errorMonitor.addBreadcrumb({ message: 'PP privateApi.addSinglePlanBlocksWidget' });
    const pageRef = { id: pageId, type: 'DESKTOP' } as PageRef;
    await editorSDK.pages.navigateTo('', { pageRef });
    await addSinglePlanWidget({ editorSDK, flowAPI, planId });
  },
  async addPlanListBlocksWidget(pageId: string, planIds?: string[]) {
    const { flowAPI, editorSDK } = getContext();
    flowAPI.errorMonitor.addBreadcrumb({ message: 'PP privateApi.addPlanListBlocksWidget' });
    const pageRef = { id: pageId, type: 'DESKTOP' } as PageRef;
    await editorSDK.pages.navigateTo('', { pageRef });
    await addPlanListBlocksWidget(editorSDK);
  },
});

export const publicApi = (editorSDK: EditorSDK): PublicPricingPlansApi => ({
  async setDemoData(plans) {
    const { applicationId } = await editorSDK.document.tpa.app.getDataByAppDefId(TOKEN, PricingPlans.appDefinitionId);
    const components = await editorSDK.tpa.app.getAllCompsByApplicationId(TOKEN, applicationId);

    if (components.length) {
      await editorSDK.tpa.data.set(TOKEN, {
        compRef: { id: components[0].id, type: 'DESKTOP' },
        key: 'demoData',
        scope: 'APP',
        value: { plans } as unknown as JsonObject,
      });
    } else {
      throw new Error('No Pricing Plans components found on site');
    }
  },
});

export const editorApi = ({
  getContext,
  platformApp,
}: {
  getContext: () => Context;
  platformApp: EditorPlatformApp<unknown>;
}): Partial<AppEditorApi> => ({
  getSelectedWidgetDesignPresetId: async ({ widgetRef }) => {
    const { editorSDK } = getContext();
    const widgetContainerRef = await getContainerRef(editorSDK, widgetRef);
    const isSinglePlanWidget = await isSinglePlanWidgetRef(editorSDK, widgetContainerRef);
    if (isSinglePlanWidget) {
      const container = (await isContainerRef(editorSDK, widgetRef))
        ? widgetRef
        : await getContainerRef(editorSDK, widgetRef);
      const planWidget = await getPlanWidget(editorSDK, container);
      return getCurrentPresetId(editorSDK, planWidget);
    }
    const preset = await editorSDK.document.application.appStudioWidgets.getPreset('', { componentRef: widgetRef });
    return preset.layout;
  },
  async appInstalled(data) {
    const { flowAPI, editorSDK, contextParams } = getContext();
    flowAPI.fedops.interactionStarted('app_installed_event');
    flowAPI.errorMonitor.addBreadcrumb({ message: 'PP editor.appInstalled' });

    const message = await Promise.race([
      new Promise<void>(async (resolve) => {
        try {
          if (platformApp.exports && typeof platformApp.exports === 'function') {
            await platformApp.exports(editorSDK, contextParams).editor?.appInstalled?.(data);
          } else {
            captureEditorException(flowAPI, new Error('platformApp.exports is not a function'));
          }
        } catch (e) {
          captureEditorException(flowAPI, e);
        }
        resolve();
      }),
      new Promise<string>((resolve) =>
        setTimeout(() => {
          resolve(
            `platformApp.exports.editor.appInstalled on appInstalled (appDefinitionId: ${data.appDefinitionId}) is stuck`,
          );
        }, 60000),
      ),
    ]);
    if (message) {
      flowAPI.errorMonitor.captureMessage(message);
    }

    const installMembersArea =
      contextParams.firstInstall &&
      data.appDefinitionId === PricingPlans.appDefinitionId &&
      contextParams.origin?.info?.type !== InstallationOriginType.SILENT_INSTALL_SITE_CREATION &&
      contextParams.origin?.info?.type !== InstallationOriginType.SILENT_INSTALL;

    if (installMembersArea) {
      flowAPI.fedops.interactionStarted('es_maybe_install_members_area');
      try {
        await maybeInstallMembersArea(editorSDK, { biData: contextParams.biData });
        flowAPI.fedops.interactionEnded('es_maybe_install_members_area');
      } catch (e) {
        captureEditorException(flowAPI, e);
      }
    }
    flowAPI.fedops.interactionEnded('app_installed_event');
  },
  async removeApp(data) {
    const { flowAPI, editorSDK, contextParams } = getContext();
    flowAPI.fedops.interactionStarted('remove_app_event');
    flowAPI.errorMonitor.addBreadcrumb({ message: 'PP editor.removeApp' });
    if (platformApp.exports && typeof platformApp.exports === 'function') {
      try {
        await platformApp.exports(editorSDK, contextParams).editor?.removeApp?.(data);
        flowAPI.fedops.interactionEnded('remove_app_event');
      } catch (e) {
        captureEditorException(flowAPI, e);
      }
    } else {
      captureEditorException(flowAPI, new Error('platformApp.exports is not a function'));
    }
  },
  async beforeRemoveApp(data) {
    const { flowAPI, editorSDK, contextParams } = getContext();
    flowAPI.fedops.interactionStarted('before_remove_app_event');
    flowAPI.errorMonitor.addBreadcrumb({ message: 'PP editor.beforeRemoveApp' });
    if (platformApp.exports && typeof platformApp.exports === 'function') {
      try {
        await platformApp.exports(editorSDK, contextParams).editor?.beforeRemoveApp?.(data);
        flowAPI.fedops.interactionEnded('before_remove_app_event');
      } catch (e) {
        captureEditorException(flowAPI, e);
      }
    } else {
      captureEditorException(flowAPI, new Error('platformApp.exports is not a function'));
    }
  },
});
