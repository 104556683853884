import { EditorSDK, PanelResolveType } from '@wix/platform-editor-sdk';
import type { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import pricingPlans from '../../.application.json';
import { captureEditorException } from '../utils/editor';
import { getStaticAssetUrl } from '../utils/get-static-asset-url';
import { setPermissionsPage } from '../utils/setPermissionsPage';

const appDefinitionId = pricingPlans.appDefinitionId;

export const trySetPermissionsPage = async (editorSDK: EditorSDK, flowAPI: EditorScriptFlowAPI) => {
  flowAPI?.fedops.interactionStarted('es_set_permissions_page');
  flowAPI?.errorMonitor.addBreadcrumb({ message: 'PP trySetPermissionsPage' });
  try {
    await setPermissionsPage(editorSDK, flowAPI, appDefinitionId);
    flowAPI?.fedops.interactionEnded('es_set_permissions_page');
  } catch (e) {
    captureEditorException(flowAPI, e, { print: true });
  }
};

export function suggestNoComponentsFix(flowAPI: EditorScriptFlowAPI, sdk: EditorSDK) {
  const t = flowAPI.translations.t;
  return suggestInstallationFix({
    flowAPI,
    sdk,
    panelOptions: {
      illustration: getStaticAssetUrl('images/pricing-plans.png'),
      shouldShowIllustration: true,
      headerText: t('es.install-fix-panel.title'),
      descriptionText: t('es.install-fix-panel.description'),
      mainActionText: t('es.install-fix-panel.main-action'),
      secondaryActionText: t('es.install-fix-panel.secondary-action'),
    },
  });
}

export function suggestNoMainPageFix(flowAPI: EditorScriptFlowAPI, sdk: EditorSDK) {
  const t = flowAPI.translations.t;
  return suggestInstallationFix({
    flowAPI,
    sdk,
    panelOptions: {
      illustration: getStaticAssetUrl('images/app-fix-illustration.png'),
      shouldShowIllustration: true,
      headerText: t('es.no-page-fix-panel.title'),
      descriptionText: t('es.no-page-fix-panel.description'),
      mainActionText: t('es.no-page-fix-panel.main-action'),
    },
  });
}
function suggestInstallationFix(params: {
  flowAPI: EditorScriptFlowAPI;
  sdk: EditorSDK;
  panelOptions: Parameters<EditorSDK['editor']['openConfirmationPanel']>[1];
}) {
  const { flowAPI, sdk, panelOptions } = params;
  if (flowAPI.environment.isADI) {
    flowAPI.errorMonitor.captureMessage('Cannot fix broken ADI site.');
    return;
  }

  sdk.editor
    .openConfirmationPanel('', panelOptions)
    .then(async (result) => {
      flowAPI.errorMonitor.captureMessage(`Fix action selected: ${result}.`);
      if (result === PanelResolveType.MAIN_ACTION) {
        try {
          flowAPI.fedops.interactionStarted('reinstall_fix');
          await sdk.application.uninstall('', { openConfirmation: false });
          await sdk.tpa.add.application('', {
            appDefinitionId,
            shouldNavigate: true,
            showPageAddedPanel: true,
          });
          flowAPI.fedops.interactionEnded('reinstall_fix');
        } catch (e) {
          captureEditorException(flowAPI, e, {
            interactionTag: 'reinstall_fix',
          });
        }
      } else if (result === PanelResolveType.SECONDARY_ACTION) {
        try {
          flowAPI.fedops.interactionStarted('uninstall_fix');
          await sdk.application.uninstall('', { openConfirmation: false });
          flowAPI.fedops.interactionEnded('uninstall_fix');
        } catch (e) {
          captureEditorException(flowAPI, e, {
            interactionTag: 'uninstall_fix',
          });
        }
      }
    })
    .catch((e) => {
      captureEditorException(flowAPI, e, {
        interactionTag: 'suggest_installation_fix',
      });
    });
}
