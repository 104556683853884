import { EditorSDK, PageData, RouterRef } from '@wix/platform-editor-sdk';
import { TpaPageId, PageRoleId } from '@wix/pricing-plans-router-utils';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { captureEditorException } from './editor';
import { PageInstallationInfo } from './isMultiPageApp';

const TOKEN = '';
const ROUTER_PREFIX = 'pricing-plans';
const NON_ROUTER_PAGES = [
  // Paywall doesn't work with our router, because the routing there is handled by thunderbolt
  TpaPageId.Paywall,
];

// Allows to identify page by it's purpose/role instead of requiring to use page IDs which are unique per site.
const PackagePickerRole = 'pricing_plans' as const;
const CheckoutRole = 'checkout' as const;
const ThankYouRole = 'thank_you' as const;
const PaywallRole = 'paywall' as const;
const CustomizationRole = 'customization' as const;

const tpaPageIdRole: Record<TpaPageId, PageRoleId> = {
  [TpaPageId.PackagePicker]: PackagePickerRole,
  [TpaPageId.Checkout]: CheckoutRole,
  [TpaPageId.ThankYou]: ThankYouRole,
  [TpaPageId.Paywall]: PaywallRole,
  [TpaPageId.PlanCustomization]: CustomizationRole,
};

export async function installRouter(
  flowAPI: EditorScriptFlowAPI,
  sdk: EditorSDK,
  expectedPages: PageInstallationInfo[],
) {
  try {
    let routerRef = await sdk.document.routers.getByPrefix(TOKEN, { prefix: ROUTER_PREFIX });
    const pages = await sdk.document.pages.getApplicationPages(TOKEN);
    const packagePickerSlug = findUri(pages, 'membership_plan_picker_tpa', 'list');
    if (!routerRef) {
      const config = {
        slugs: Object.fromEntries(
          pages.filter((page) => page.tpaPageId !== 'thank_you_page').map((page) => [page.tpaPageId, page.pageUriSEO]),
        ),
      };
      routerRef = await sdk.document.routers.add(TOKEN, { prefix: ROUTER_PREFIX, config });
    }
    await ensureRouterConnectedPages({
      sdk,
      routerRef,
      connectablePages: expectedPages.filter((page) => !NON_ROUTER_PAGES.includes(page.tpaPageId)),
      slugs: { [TpaPageId.PackagePicker]: packagePickerSlug },
    });
  } catch (e) {
    captureEditorException(flowAPI, e, {
      interactionTag: 'router_install',
    });
  }
}

async function ensureRouterConnectedPages(params: {
  sdk: EditorSDK;
  routerRef: RouterRef;
  connectablePages: PageInstallationInfo[];
  slugs: { [key in TpaPageId]?: string };
}) {
  const { sdk, routerRef, connectablePages, slugs } = params;
  const routerData = await sdk.document.routers.get(TOKEN, { routerRef });
  const connectedPages = routerData.pages.flatMap((page) => page.pageRoles);
  for (const page of connectablePages) {
    if (!connectedPages.includes(tpaPageIdRole[page.tpaPageId])) {
      await connectPageToRouter(page.tpaPageId, sdk, routerRef, slugs[page.tpaPageId]);
    }
  }
}

export async function uninstallRouter(sdk: EditorSDK) {
  const routerRef = await sdk.document.routers.getByPrefix(TOKEN, { prefix: ROUTER_PREFIX });
  if (routerRef) {
    await sdk.document.routers.remove(TOKEN, { routerRef });
  }
}

function findUri(pages: PageData[], tpaPageId: string, fallback: string): string {
  return pages.find((page) => page.tpaPageId === tpaPageId)?.pageUriSEO ?? fallback;
}

async function connectPageToRouter(tpaPageId: TpaPageId, sdk: EditorSDK, routerRef: RouterRef, slug?: string) {
  await sdk.document.routers.pages.connect(TOKEN, {
    routerRef,
    pageRef: await sdk.document.tpa.getPageRefByTPAPageId(TOKEN, { tpaPageId }),
    pageRoles: [tpaPageIdRole[tpaPageId]],
    slug,
  });
}
