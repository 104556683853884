import { withEcomPlatformData } from '@wix/ecom-platform-sdk';
import { MA_APP_IDS } from '@wix/members-area-integration-kit';
import { EditorPlatformApp, ContextParams, EditorSDK } from '@wix/platform-editor-sdk';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import type { EditorReadyFn, EditorScriptFlowAPI, GetAppManifestFn } from '@wix/yoshi-flow-editor';
import pricingPlans from '../.application.json';
import {
  appManifest,
  editorReadyInternal,
  privateApi,
  publicApi,
  editorApi,
  maybeCreateMembersAreaInstallLogger,
  reportPricingPlansInstallationStage,
  trySetPermissionsPage,
} from './editor';
import { captureEditorException } from './utils/editor';

export const TOKEN = '';
export const appDefinitionId = pricingPlans.appDefinitionId;

const ACTION_APP_INSTALLED = 'appInstalled';

export type Context = {
  editorSDK: EditorSDK;
  flowAPI: EditorScriptFlowAPI;
  contextParams: ContextParams;
};

const { setContext, getContext } = (() => {
  let ctx: Context;
  return {
    setContext: (_ctx: Context) => {
      ctx = _ctx;
    },
    getContext: () => ctx,
  };
})();

const platformApp = withEcomPlatformData(
  {
    editorReady: editorReadyInternal,
    exports: (editorSDK: EditorSDK, contextParams: ContextParams) => ({
      editor: {
        appInstalled: async (data: { appDefinitionId: string }) => {
          const { flowAPI } = getContext();
          flowAPI.fedops.interactionStarted('es_app_installed');
          flowAPI.errorMonitor.addBreadcrumb({ message: 'PP appInstalled', data });
          try {
            if (data.appDefinitionId === appDefinitionId) {
              await reportPricingPlansInstallationStage({
                editorSDK,
                stage: ACTION_APP_INSTALLED,
                flowAPI,
              });
              await trySetPermissionsPage(editorSDK, flowAPI);
            }
            flowAPI.fedops.interactionEnded('es_app_installed');
          } catch (e) {
            captureEditorException(flowAPI, e, { print: true });
          }
        },
      },
    }),
  } as EditorPlatformApp,
  {
    shouldInstallMembersArea: false,
    shouldSkipEcomInstallation: true,
    usingInfraAppDependency: false,
    ecomComponents: {},
  },
  {
    installAutomatically: false,
    disableADI: false,
    membersAreaApps: [MA_APP_IDS.MY_SUBSCRIPTIONS, MA_APP_IDS.MY_WALLET],
    onEvent: maybeCreateMembersAreaInstallLogger(getContext()),
    experimentalOverride: {
      experiment: {
        name: TPA_EXPERIMENTS.INSTALL_ECOM,
        scope: 'viewer-apps-1522827f-c56c-a5c9-2ac9-00f9e6ae12d3',
      },
      config: {
        membersAreaApps: [
          MA_APP_IDS.MY_SUBSCRIPTIONS,
          MA_APP_IDS.MY_WALLET,
          MA_APP_IDS.MY_ADDRESSES,
          MA_APP_IDS.MY_ORDERS,
        ],
      },
    },
  },
);

export const editorReady: EditorReadyFn = async (editorSDK, token, contextParams, flowAPI) => {
  setContext({
    editorSDK,
    flowAPI,
    contextParams,
  });
  flowAPI.fedops.interactionStarted('es_editor_ready_external');
  flowAPI.errorMonitor.addBreadcrumb({ message: 'PP editorReady' });
  try {
    // @ts-expect-error
    await platformApp.editorReady(editorSDK, token, contextParams, flowAPI);
    flowAPI.fedops.interactionEnded('es_editor_ready_external');
  } catch (e) {
    captureEditorException(flowAPI, e, { print: true });
  }
};
export const exports: EditorPlatformApp['exports'] = (editorSDK, contextParams) => {
  return {
    private: privateApi(getContext),
    public: publicApi(editorSDK),
    editor: editorApi({ getContext, platformApp }),
  };
};

export const getAppManifest: GetAppManifestFn = appManifest(setContext);
