import type { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { toError } from './errors';

enum Severity {
  Fatal = 'fatal',
  Error = 'error',
  Warning = 'warning',
  Log = 'log',
  Info = 'info',
  Debug = 'debug',
  Critical = 'critical',
}

interface CaptureExceptionOptions {
  print: boolean;
  interactionTag: string;
}

export function captureEditorException(
  flowAPI: EditorScriptFlowAPI,
  err: unknown,
  options: Partial<CaptureExceptionOptions> = {},
) {
  if (typeof err === 'object' && err !== null && !(err instanceof Error)) {
    flowAPI.errorMonitor.addBreadcrumb({
      level: Severity.Error,
      message: 'Error object.',
      data: err,
    });
  }
  flowAPI.errorMonitor.captureException(
    toError(err),
    options.interactionTag
      ? {
          tags: {
            interaction: options.interactionTag,
          },
        }
      : {},
  );
  if (options.print) {
    console.error(err);
  }
}
